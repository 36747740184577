const furdok = [
	{
		title: 'Veli Bej Fürdő',
		img: 'http://www.termalfurdo.hu/upload/images/Galeria/furdo/irgalmasok_veli_bej_furdoje/termalfurdo_veli_bej_irgalmasok_1.jpg',
		text: 'Az ottomán uralom alatt alapított Veli Bej Fürdőt a közelmúltban modern formában újranyitották. Az épületben szauna, infraszauna, gőzkabin, élményzuhany, családi fülkék, súlyfürdő, Kneipp-sétáló, jakuzzi és persze egy feszített víztükrű gyógyvizes medence is rendelkezésünkre áll. ',
		face: 'https://www.facebook.com/Irgalmasok-Veli-Bej-F%C3%BCrd%C5%91je-hivatalos-104018515326685/'
	},
	{
		title: 'Gellért Fürdő',
		img: 'https://www.termalfurdo.hu/upload/images/Galeria/furdo/gellert_gyogyfurdo_es_uszoda/termalfurdo_gellert_gyogyfurdo_6.jpg',
		text: 'A Gellért a leghíresebb (és legdrágább) műemlék fürdő, mely a XX. század elején nyitotta meg kapuit Budán, a Gellért-hegy és a Szabadság híd ölelésében. A pompás, art-nouveau stílusú fürdő a Gellért Szálló része, amely közel száz évig a fővárosi szállodák ikonja volt, és bár mára sokat vesztett fényéből, még mindig a jómód és a kényelem szimbóluma. ',
		face: 'https://www.facebook.com/GellertGyogyfurdo'
	},
	{
		title: 'Rudas Fürdő',
		img: 'https://www.rudasfurdo.hu/static/dokumentumtar/Rudas/_SIM1147a.jpg',
		text: 'A Rudas Fürdő a legidősebb tradicionális termálfürdő a városban, amely már 1520 óta működik. A szaunák, gőzkabinok, a tetőn terpeszkedő panorámás napozóterasz és jacuzzi, valamint a fiatalokat vonzó, éjszakai nyitvatartás és bulik tovább turbózzák a Rudas presztízsét és népszerűségét.',
		face: 'http://www.rudasfurdo.hu/'
	},
	{
		title: 'Széchenyi Fürdő',
		img: 'https://www.szechenyifurdo.hu/static/baths/8/home-boxes/833.jpg',
		text: 'A Széchenyi Gyógyfürdő Európa egyik legnagyobb termálfürdő-komplexuma, amelynek téli éjszakákon gőzölgő kültéri medencéje a vízben sakkozgató kedélyes öregurakkal világhírű Budapest-szimbólum. A Városligetben álló fürdőt a XX. század elején építették neo-barokk stílusban.',
		face: 'https://www.facebook.com/szechenyifurdo'
	}
];

const ettermek = [
	{
		title: 'Mazel Tov',
		img: 'https://diningguide.hu/wp-content/uploads/2020/06/mazel-tov-nyito-scaled.jpg',
		text: 'A Mazel Tov a budapesti zsidó negyed kultúrmulatója, amelyet valahol a városi kertek, a nyitottság és az izgalmas közel-keleti, azon belül is a tel-avivi konyha metszéspontjában kell keresni. Ha megtaláltuk, látványkonyhában készülő, gyakran pitával kiegészített ételek, csinos koktélok és sokféle kulturális program lesz a jutalom. A "rométterem" egyik kiemelt kulturális célja, hogy teret biztosítson mindenkinek, akik hisznek a nyitott és befogadó világban, a programok is ezt szeretnék üzenni. ',
		face: 'https://www.facebook.com/mazeltovbp/'
	},
	{
		title: 'Dobrumba ',
		img: 'https://etterem.hu/img/max960/p14833n/1528209026-7373.jpg',
		text: 'Atlasztól Ararátig, Boszporusztól Gibraltárig - ez a Dob és Rumbach Sebestyén utca sarkán található, frappánsan Dobrumba névre keresztelt food bar gasztronómiai és szellemiségbeli irányvonala. Hangulatban New York menő bisztróit idézi, míg étlapja Berényi András executive séf vezetésével és a tulajdonosi kör inspirációi által készült el. Mezze-k, mártogatók, szuperfriss zöldségek adják a bázist. ',
		face: 'https://www.facebook.com/dobrumba/'
	},
	{
		title: 'Pingrumba',
		img: 'https://streetkitchen.hu/wp-content/uploads/2020/09/pingrumba-5-scaled.jpg',
		text: 'A Pingrumbát a Dobrumba budai nagy tesójának is hívhatnánk, ugyanis a Széll Kálmán téren egy tágas belterű keleti étteremre bukkanhatunk. Az autentikus dizájnról az alapítók utazásai és a bolhapiacokon vadászott holmik gondoskodnak. Ez a személyesség köszön vissza az étlapról is, hiszen a kínálatot is a saját kedvenc ételeikből állították össze „Kairótól Kalkuttáig”. ',
		face: 'https://www.facebook.com/PINGRUMBA/'
	},
	{
		title: 'Berlin Bisztró',
		img: 'https://welovebudapest.com/i/fe/0610-berlinerbistro-km13.exact1980w.JPG',
		text: 'Török Roland tulajdonost – egykori berlini egyetemistát – a német főváros multikulturális és kulináris sokszínűsége inspirálta az erzsébetvárosi Klauzál tér legújabb helyének kialakításánál, ahová akár csak egy német csapolt sörre is bőven megéri beugrani. A hangulatért és az ételekért pedig pláne!',
		face: 'https://facebook.com/berlinbudapest/'
	},
	{
		title: 'Kismező',
		img: 'https://welovebudapest.com/i/5a/kismezo-sharing-molna-r-da-niel-socially.exact1980w.jpeg',
		text: 'A Pesti Disznó bezárását követően Gerendai Károly, Besztercsényi András, Durgó Lajos és Pusztási Attila tulajdonosok egy olyan, a mostani trendekhez igazodó koncepcióval nyitottak újra – immár Kismező néven –, ahol az étterem a Broadway vibráló hangulatát eleveníti fel, miközben megosztásra tervezett, úgynevezett „small plate” fogásokkal, canotto pizzákkal, valamint széles és tradicionális borsorral várja a vendégeket.',
		face: 'https://www.facebook.com/kismezobudapest/'
	},
	{
		title: 'Babka Budapest',
		img: 'https://welovebudapest.com/i/bc/babka-budapest.inbox960x634.jpg',
		text: 'A Babka Budapest a Pozsonyi úton található izraeli ihletésű étterem egy kis budapesti csavarral. A közel-keleti konyha a fő profil, mely összemosva jelenti az arab, az izraeli, az örmény, a török és a grúz ízeket, de igazából a hely egy nyitott, nemzetközi projekt, hiszen rendelhetünk shakshukát, sabihot, de alkalomadtán császármorzsát is. ',
		face: 'https://www.facebook.com/babkabudapest/'
	}
];

const programok = [
	{
		title: 'Svábhegyi csillagvizsgáló',
		img: 'https://welovebudapest.com/i/ce/svabhengyi-csillagvizsgalo-210802-szabo-gabor-025.inbox960x634.jpg',
		text: 'A Svábhegyi Csillagvizsgáló egy interaktív csillagászati élményközpont, ahol megnézhetjük akár a vörösen izzó Napot, a Hold krátereit vagy a galaxisokat is. ',
		face: 'https://svabhegyicsillagvizsgalo.hu/programok-a-csillagvizsgaloban'
	},
	{
		title: 'Hosszúlépés - Kőbányai selyemút',
		img: 'https://hosszulepes.org/files/slide/image/1375/fe_900_500_IMG_20190206_122737.jpg',
		text: 'Budapest, a kávéváros.',
		face: 'https://hosszulepes.org/tour-details/talpig-feketeben'
	},
	{
		title: 'Hosszúlépés - Talpig feketében',
		img: 'https://hosszulepes.org/files/slide/image/1375/fe_900_500_IMG_20190206_122737.jpg',
		text: 'Budapest ismeretlen kínai negyede, és minden, ami mögötte van: feltárul előttünk a kőbányai Monori Center titkos világa!',
		face: 'https://hosszulepes.org/tour-details/kobanyai-selyemut'
	},
	{
		title: 'Hosszúlépés - Astória hotel titkai',
		img: 'https://hosszulepes.org/files/slide/image/2093/fe_900_500_astoria_honlap_cover_1.jpg',
		text: 'Lehallgatott diplomaták, letartóztatott miniszterek, filmsztárok neglizsében. Budapest egyik legrégebbi hotelének kalandos története.',
		face: 'https://hosszulepes.org/tour-details/nem-hiszteria-astoria'
	},
];

const streetfood = [
	{
		title: 'Laffa',
		img: 'https://welovebudapest.com/i/ea/laffa-polyak-attila-002.exact1980w.jpg',
		text: 'Semmi nem mutatja talán jobban egy város gasztronómiai fejlődését, mint amikor azt látjuk, hogy a mindennapok street food kínálata is egyre magasabb minőségre és változatosságra törekszik, miközben az őszintén kedves kiszolgálással – és finom ételeivel – folyamatosan növeli törzsközönségét. A Wesselényi utcában nyáron nyílt Laffa pont az ilyen üdítő helyek egyik legjobb példája.',
		face: 'https://www.facebook.com/laffabp/'
	},
	{
		title: 'Baba Ganoush',
		img: 'https://welovebudapest.com/i/d8/babaganoush-220513-majorkata-13.exact1980w.jpg',
		text: 'A budapesti bulinegyedben talán az is van, ami amúgy nem létezik. Erzsébetváros közepének street food kínálata ráadásul most egy marokkói ihletésű parányi hellyel gazdagodott: shawarma, különleges fűszerezésű borjúarayes, marokkói báránykolbász ropogós burgonyával és persze a névadó baba ganoush.',
		face: 'https://www.facebook.com/babaganoushbp/'
	},
	{
		title: 'Laziza',
		img: 'https://welovebudapest.com/i/07/laziza-220127-szabo-gabor-025.inbox960x634.jpg',
		text: 'A már jól ismert török döner és a görög gírosz után az új kedvencünk a szír street food csoda, a shawarma. Az alapok első ránézésre hasonlóak, de a végeredmény teljesen különböző. Ez egy szafttal enyhén átitatott arab kenyér, a remekül fűszerezett, omlós hús, a petrezselyem, a hagyma, a paradicsom és a fokhagymás házi tahina szósz. ',
		face: 'https://www.facebook.com/LazizaBudapest/'
	},
	{
		title: 'Edy’s French Tacos',
		img: 'https://imageproxy.wolt.com/venue/5fb684c05cf05f8977996c00/3c547772-76a9-11eb-802b-ba98426b8d88_xdogpmla.jpeg',
		text: 'A French tacos a legenda szerint néhány arab kebabos válasza a hamburger, a döner és a burrito uralta street food kínálat alternatívakeresésére a 2000-es évek elején. Franciaországban olyan hatalmasat szólt a dolog, hogy Edy, a magyar srác egészen Vietnamból hozta végül haza megmutatni nekünk.',
		face: 'https://www.facebook.com/edysfrenchtacos/'
	},
];

export {furdok,programok,streetfood,ettermek}