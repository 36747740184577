import React from 'react'
import {  Accordion } from 'react-bootstrap'
import { streetfood } from "../Database";
import Item from "../Item";
import Pageheader from '../partials/Pageheader';

function Streetfood() {
	return (
		<>
			<Pageheader>Streetfood</Pageheader>
			<Accordion flush>
				{streetfood.map((item, index) =>
					<Item img={item.img} face={item.face} title={item.title} id={index} key={index}>{item.text}</Item>
				)}
			</Accordion>
		</>
	)
}

export default Streetfood