import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'

function Pageheader({children}) {
	return (
		<Container className="my-3">
			<Row>
				<Col>
					<h3 className="fw-bold">{ children }</h3>
				</Col>
				<Col xs='auto'>
					<Button variant='light' href="/">🔙 Vissza</Button>
				</Col>
			</Row>
		</Container>
	)
}

export default Pageheader