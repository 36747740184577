import React from 'react'
import { Container, Row, Col, Nav, Alert } from 'react-bootstrap'

function Intro() {
	return (
		<Container>
			<Alert>
				Igen, jól látod, ez egy progresszív mobilapp. Csak neked! ❤️
			</Alert>
			<Row className="my-4 g-4">
				<Col xs="4"><a href="/intro.jpeg"><img src="/intro.jpeg" className="img-fluid" /></a></Col>
				<Col>
					<p>
						Idén egy <strong>fullos</strong> pesti napot ➕ egy <strong>ráadás</strong> programot kapsz 🎁 ajándékba.
					</p>
					<p>A kulcsszó a <strong>kelet varázsa</strong> modern formában. Eköré szerveztem az idei ajánlómat, még akkor is ha belecsempésztem egy-két nem keletit is.</p>
					<p>
						A főjándék két elemet tartalmaz majd, egy 🚙 program, egy 🥄 étterem kategóriát.
					</p>
				</Col>
			</Row>

			<Nav fill className='justify-content-center mb-4 fs-1'>
				<Nav.Link href="/furdok">🛀</Nav.Link>
				<Nav.Link href="/programok">🎒</Nav.Link>
				<Nav.Link href="/ettermek">🥄</Nav.Link>
			</Nav>

			<hr />
			<p>Ezen kívül választhatsz egy ➕ <strong> ráadás</strong> kisprogramot amit bármikor beválthasz egy évig, hogy egész évben meg tudjon maradni egy darabka a szülinapodból. 😉</p>
			<Nav fill className='justify-content-center mb-4 fs-1'>
				<Nav.Link href="/streetfood">🌮</Nav.Link>
			</Nav>


		</Container>
	)
}

export default Intro