import Menu from "./Header";
import Intro from "./pages/Intro";
import Furdok from "./pages/Furdok";
import Programok from "./pages/Programok";
import Streetfood from "./pages/Streetfood";
import Ettermek from "./pages/Ettermek";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Alert } from "react-bootstrap";

function App() {
  return (
    <div className="App">
      <Alert className="m-4 d-none d-lg-block">Kérlek nézd mobilról, ez egy mobilapp!</Alert>
      <div className="d-block d-lg-none">
        <Menu />

        <BrowserRouter>
          <Routes>
            <Route index element={<Intro />} />
            <Route path="furdok" element={<Furdok />} />
            <Route path="ettermek" element={<Ettermek />} />
            <Route path="streetfood" element={<Streetfood />} />
            <Route path="programok" element={<Programok />} />
          </Routes>
        </BrowserRouter>

      </div>

    </div>
  );
}

export default App;
