import React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import {Container, Nav} from 'react-bootstrap';

function Menu() {
	return (
		<Navbar bg="light" className="shadow-sm mb-3 py-3" expand="lg">
			<Container>
				<Navbar.Brand className='navbar-brand mx-auto text-uppercase fw-bold' href="/">#Fanni(n)App</Navbar.Brand>
				{/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/programok">Programok</Nav.Link>
            <Nav.Link href="/furdok">Fürdők</Nav.Link>
            <Nav.Link href="/ettermek">Éttermek</Nav.Link>
            <Nav.Link href="/streetfood">Streetfood</Nav.Link>
          </Nav>
        </Navbar.Collapse>
			</Container>
		</Navbar>
	)
}

export default Menu