import React from 'react'
import {Accordion, Row, Col, Button} from 'react-bootstrap';

function Item({id, title, img, face, children}) {
	return (
		<Accordion.Item eventKey={ id }>
			<Accordion.Header>{ title }</Accordion.Header>
			<Accordion.Body>
				<Row className='g-4'>
					{img &&
						<Col xs="12">
							<a href={img} target='_blank'>
								<img style={{maxWidth:'100%'}} src={img} alt="" />
							</a>
						</Col>
					}
					<Col>
						<p>{ children }</p>
						<Button href={face}>weboldal</Button>
					</Col>
				</Row>
			</Accordion.Body>
		</Accordion.Item>
	)
}

export default Item